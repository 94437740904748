.show-again-button {
  position: fixed;
  bottom: 18%;
  right: 33px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-size: 24px;
  transition: background-color 0.3s ease;
}

.show-again-button:hover {
  background-color: #0056b3;
}

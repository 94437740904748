.navbar {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.45);
  border: 1px solid rgba(209, 213, 219, 0.146);
}
.header-nav-logo {
  width: 250px;
  height: 120px;
}
.iata-container {
  margin-right: 40px;
}

.navbar a {
  color: #000 !important;
  transition: color 0.3s, font-weight 0.3s;
}

.navbar a:hover {
  color: #ffcc00 !important;
}

.navbar .nav-link.active {
  color: #ffcc00 !important;
  font-weight: 500;
}

.navbar .navbar-nav .nav-link {
  padding: 35px 15px;
}

.navbar .btn-lg-square {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-contact {
  color: #ffcc00;
  font-weight: 500;
}

.navbar-toggler,
.navbar-toggler:focus {
  border: none !important;
  outline: none !important;
}

/* Sticky navbar */
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1030;
}

@media (max-width: 1100px) {
  .iata-container {
    margin-right: 0px;
  }
}

/* Mobile-specific styles */
@media (max-width: 991.98px) {
  .navbar .navbar-nav {
    margin-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    background-color: var(--dark);
  }

  .navbar .navbar-nav .nav-link {
    padding: 10px 0;
    text-align: center;
  }

  .call-us-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .navbar .d-flex {
    display: block;
  }

  /* Ensure the navbar list items show at the bottom of the mobile dropdown */
  .navbar-collapse {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }

  .navbar-collapse .navbar-nav {
    flex-grow: 1;
  }

  .call-us-section {
    opacity: 1;
    visibility: visible;
    transition: none;
  }
}

/* Desktop-specific styles */
@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    visibility: hidden;
    top: 100%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
    transition: transform 0.5s, visibility 0.5s, opacity 0.5s;
    opacity: 0;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .header-nav-logo {
    width: 150px;
    height: 100px;
  }
}

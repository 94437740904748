.single-service-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.single-service-data .service-model {
  display: flex;
  flex-direction: column;
  border: 1px solid #fffdf3;
  /* background-color: #fafafa; */
  padding: 15px;
  border-radius: 8px;
  transition: all 0.3s;
  margin-bottom: 20px;
}

/* .single-service-data .service-model:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transition: all 0.3s;
} */

.single-service-data .details-container {
  flex: 1;
}

.service-details-container-title {
  color: #0ccaf0;
  font-weight: bold;
  font-size: clamp(1.25rem, 0.7813rem + 1.5vw, 1.625rem);
  text-transform: uppercase;
}

.service-details-container-title::after {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  background-color: #fab428;
  margin-top: 10px;
}

.single-service-data .image-container {
  margin-top: 15px;
}

.single-service-data img {
  position: sticky;
  top: 25%;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.single-service-data ul {
  list-style-type: none;
  padding: 0;
}

.single-service-data li {
  margin: 10px 0;
}

.service-container-single-container .contact-section {
  text-align: center;
  margin: 30px 0px;
  background-color: #0092cc;
  color: #fff;
  padding: 30px;
  border-radius: 10px;
}

.service-container-single-container .contact-section h2 {
  font-family: "Russo One", sans-serif;
  font-size: 2.2rem;
  margin-bottom: 15px;
}

.service-container-single-container .contact-section p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.service-container-single-container .contact-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  font-family: "Russo One", sans-serif;
  color: #fff;
  background-color: #fab429;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.service-container-single-container .contact-button:hover {
  background-color: #f1b744;
}

@media (min-width: 768px) {
  .single-service-data .service-model {
    flex-direction: row;
  }

  .single-service-data .image-container {
    margin-left: 20px;
    flex: 1;
    max-width: 200px;
  }

  .single-service-data .details-container {
    flex: 2;
  }
}

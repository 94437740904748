.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  overflow-y: scroll;
}

.offer-popup-container .popup-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.offer-popup-container .offer-book-header {
  background-image: radial-gradient(
    circle 975px at 2.6% 48.3%,
    rgba(0, 8, 120, 1) 0%,
    rgba(95, 184, 224, 1) 99.7%
  );
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.offer-popup-container .popup-close {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 30px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #020d7d;
  background-image: radial-gradient(
    circle 331px at 1.4% 52.9%,
    rgba(255, 236, 2, 1) 0%,
    rgba(255, 223, 2, 1) 33.6%,
    rgba(255, 187, 29, 1) 61%,
    rgba(255, 175, 7, 1) 100.7%
  );
  border: 2px solid rgba(255, 175, 7, 0.5);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.offer-popup-container .pop-container-offer-form {
  color: #fab428;
}

.offer-popup-container .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.offer-popup-container .form-control,
.offer-popup-container .form-select,
.offer-popup-container .form-control textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.offer-popup-container .offer-book-bnt {
  /* background-color: #007bff; */
  background-image: radial-gradient(
    circle 331px at 1.4% 52.9%,
    rgba(255, 236, 2, 1) 0%,
    rgba(255, 223, 2, 1) 33.6%,
    rgba(255, 187, 29, 1) 61%,
    rgba(255, 175, 7, 1) 100.7%
  );
  color: #fff;
  border: none;
  /* padding: 10px 20px; */
  border-radius: 4px;
  cursor: pointer;
}

.offer-popup-container .btn-primary:hover {
  background-color: #0056b3;
}

.offer-popup-container .error {
  color: red;
  font-size: 0.875em;
  margin-top: 5px;
}

.offer-popup-container-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

@media (min-width: 992px) {
  .offer-popup-container .form-container {
    display: flex;
    flex-wrap: wrap;
  }

  .offer-popup-container .form-group {
    flex: 1;
    min-width: 45%;
    margin-right: 20px;
  }

  .offer-popup-container .form-group:nth-child(even) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .offer-popup-container .popup-content {
    margin-top: 50%;
  }
  .form-container {
    display: block;
  }
}
@media (max-width: 768px) {
  .offer-popup-container .popup-content {
    margin-top: 70%;
  }
  .form-container {
    display: block;
  }
}

@media (max-width: 400px) {
  .offer-popup-container .popup-content {
    margin-top: 120%;
  }
  .offer-popup-container .form-container {
    display: block;
  }
}

.serviceMainContainer .serviceHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
}

::-webkit-scrollbar {
  display: none;
}
.serviceHeader {
  font-size: clamp(1.5rem, -1.5rem + 8vw, 2.5rem);
  font-weight: bold;
  margin-right: 10px;
  color: #edb139;
  text-align: center;
}

.lineSeparator {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;
}

.lineSeparator span {
  margin: 0;
  font-size: 1rem;
  color: #007bff;
}

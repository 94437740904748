.SubAllbanners-hero-image-container {
  overflow: hidden !important;
}
.SubAllbanners-hero-image {
  display: inline-block;
  width: 100% !important;
  height: 400px;
  background: linear-gradient(
      to bottom,
      rgba(4, 40, 111, 0.639),
      rgba(0, 0, 0, 0.516)
    ),
    url("../../../assets/banner/aboutHeroImage.png") no-repeat center center;
  background-size: cover;
  /* background-attachment: fixed; */
  animation: zoom 10s infinite ease-in-out;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

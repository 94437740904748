#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: initial !important;
}

html {
  /* max-height: 100vh !important; */
  /* overflow-x: hidden; */
}
body {
  margin: 0;
  padding: 0;
  max-height: 100vh !important;
  font-family: "Radio Canada", sans-serif;
  overflow-x: hidden !important;
}

/* html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
}
body {
  padding: 0 !important;
  overflow-x: hidden;
  margin: 0 !important;
  line-height: inherit;
  display: block;
} */

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.no-price {
  color: green !important;
}

/* li {
  list-style-type: none;
} */

@media (max-width: 768px) {
  p {
    font-size: 14px;
    /* text-align: justify !important; */
  }
}

.speed-dial-container {
  position: fixed;
  bottom: 80px;
  right: 80px;
}

@media (max-width: 768px) {
  .speed-dial-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
}
